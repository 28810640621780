import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ProspectNavContext } from '../../context/ProspectNavContext';
import HamburgerMenuIcon from '../HamburgerMenuIcon';
import fffLogo from '../../assets/fff-logo.svg';
import MobileMenu from './MobileMenu';
import ProspectBanner from './ProspectBanner';
import ProspectNavVariants from './ProspectNavVariants';
import { GROWTH_GLOBAL_NAV } from '../../utils/featureFlagVariants/growth-global-nav';
import styles from './ProspectHeaderContainer.module.css';
import useInView from '../../hooks/useInView';
import { isVariation } from '../../helpers/functions/isVariation';

const { VARIATION_A, VARIATION_D, VARIATION_B, VARIATION_C } = GROWTH_GLOBAL_NAV;
const ProspectHeaderContainer = ({ toggleMenu, isOpenMenu }) => {
  const {
    env,
    isMobile,
    isTablet,
    mainProps: { variationKey, showCart },
  } = useContext(ProspectNavContext);
  const { isInView, elementRef: headerRef } = useInView();
  const shouldShowCart = showCart && variationKey !== VARIATION_A;

  const contentWrapper = classNames(styles.contentWrapper, {
    [styles.contentWrapperVariationB]:
      isVariation(variationKey, VARIATION_B) || isVariation(variationKey, VARIATION_C),
    [styles.contentWrapperVariationD]: isVariation(variationKey, VARIATION_D),
    [styles.contentWrapperPosition]: !shouldShowCart,
  });
  const showProspectBanner = (isMobile || isTablet) && variationKey !== VARIATION_A;
  return (
    <>
      <header className={styles.header} ref={headerRef}>
        <div data-testid="content-wrapper" className={contentWrapper}>
          {(isMobile || isTablet) && <HamburgerMenuIcon isOpen={isOpenMenu} onClick={toggleMenu} />}
          <a className={styles.fffLogo} href={env.REACT_APP_BASE_URL}>
            <img src={fffLogo} alt="fabfitfun logo" />
          </a>
          <ProspectNavVariants isInView={isInView} />
        </div>
      </header>
      {(isMobile || isTablet) && <MobileMenu variationKey={variationKey} isOpenMenu={isOpenMenu} />}
      {showProspectBanner && (
        <ProspectBanner isMobile={isMobile} isTablet={isTablet} variationKey={variationKey} />
      )}
    </>
  );
};

ProspectHeaderContainer.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  isOpenMenu: PropTypes.bool.isRequired,
};

export default ProspectHeaderContainer;

import request from './request';
import { getEnv } from '../environment';

const { REACT_APP_STORE_FRONT_API_URL: storeFront } = getEnv();

export const RECOMMENDATION_API_URL = (
  leadUuid: string,
  cta?: string,
  device?: string,
  anonymousId?: string,
) => {
  const url = `${storeFront}/personalized/recommendations/lead-uuid/${leadUuid}?countryIsoCode=US&campaignType=CUSTOMIZE`;

  const params = new URLSearchParams({
    ...(cta && { cta }),
    ...(device && { device }),
    ...(anonymousId && { anonymousId }),
  });

  return `${url}&${params.toString()}`;
};

interface Product {
  brandName: string;
  currentStock: number;
  optionId: number;
  productName: string;
  psProductId: number;
  quantitySold: number;
  questionId: number;
  rating: number;
  retailPrice: number;
  salePrice: number;
  score: number;
  sku: string;
  taxonomyId: number;
  thumbnailUrl: string;
}

export interface RecommendationsApiResponse {
  campaignType: string;
  otherProducts: Record<string, Product[]>;
  personalizedRecommendations: Product[];
}

interface GetRecommendationsParams {
  leadUuid: string;
  cta?: string;
  device?: string;
  anonymousId?: string;
}

export const getRecommendations = async ({
  leadUuid,
  anonymousId,
  cta,
  device,
}: GetRecommendationsParams): Promise<RecommendationsApiResponse> => {
  return request({
    path: RECOMMENDATION_API_URL(leadUuid, cta, device, anonymousId),
  });
};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styles from './ProgressNav.module.css';
import ProgressNavNode from './ProgressNavNode';
import { nmfNavigation } from './progressNavNodeData';

const NewMemberNav = ({
  step,
  hideCustomizeNode = false,
  hideSurveyNode = false,
  hideBoostNode,
}) => {
  const nodesToHide = [];
  hideCustomizeNode && nodesToHide.push('customize');
  hideSurveyNode && nodesToHide.push('survey');
  hideBoostNode && nodesToHide.push('boost');
  const nmfNavigationData = nmfNavigation.filter((node) => !nodesToHide.includes(node.key));

  const [currentNode, setCurrentNode] = useState(-1);
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)',
  });
  const viewSize = isDesktop ? 'large' : 'small';

  useEffect(() => {
    const getCurrentNode = () => {
      const index = nmfNavigationData.findIndex((x) => x.key.toLowerCase() === step.toLowerCase());
      setCurrentNode(index);
    };

    getCurrentNode();
  }, [step, nmfNavigationData]);

  return (
    <div className={`${styles.navigation} ${styles[viewSize]}`}>
      {nmfNavigationData.map((node, index) => {
        const selected = index <= currentNode;
        return (
          <ProgressNavNode
            key={node.title}
            number={`${index + 1}`}
            item={node}
            selected={selected}
          />
        );
      })}
    </div>
  );
};

NewMemberNav.defaultProps = {
  hideCustomizeNode: false,
};

NewMemberNav.propTypes = {
  step: PropTypes.string.isRequired,
  hideCustomizeNode: PropTypes.bool,
};

export default NewMemberNav;
